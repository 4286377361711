<template>
  <div class="user-list main-panel large">
    <h3 class="panel-title">用户列表</h3>
    <div class="top">
      <el-form inline>
        <el-form-item label="个人名">
          <el-input v-model="form.staffName" placeholder="个人名" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" icon="el-icon-search" size="mini" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" height="500" v-loading="isLoading">
      <el-table-column align="center" label="用户名" prop="username"></el-table-column>
      <el-table-column align="center" label="个人名" prop="name"></el-table-column>
      <el-table-column align="center" label="手机号" prop="phone"></el-table-column>
      <el-table-column align="center" label="职位">
        <template #default="scope">{{ scope.row.position || "无" }}</template>
      </el-table-column>
      <el-table-column align="center" label="已绑定设备">
        <template #default="scope">{{ scope.row.deviceNo ? "是" : "否" }}</template>
      </el-table-column>
      <el-table-column align="center" label="是否可用">
        <template #default="scope">
           <el-switch v-model="scope.row.customerflagBool"
                      :loading="scope.row.flagLoading"
                      @change="flag => changeFlag(flag, scope.$index)" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <el-popconfirm v-if="scope.row.deviceNo" title="确认解绑吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="unbind(scope.row.id)">
              <template #reference>
                <i class="unbind-icon el-icon-unlock" title="解绑"></i>
              </template>
            </el-popconfirm>
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer(scope.row)"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(scope.row.id)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>

      <template #append>
        <div class="load-more"
             :class="{'no-more': noMore}"
             v-if="list.length"
             @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
      </template>
    </el-table>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              @handle="layerInfo = {}; search();"
              :layer-info="layerInfo"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  data() {
    return {
      form: {
        staffName: "",
        currentPage: 1,
        pageSize: 50
      },
      list: [],
      isLoading: false,
      noMore: false,

      layerComponent: undefined,
      layerInfo: {}
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    async search() {
      this.form.currentPage = 1
      this.noMore = false
      this.list = []
      await this.getList()
    },

    async getList() {
      if (this.noMore) return false;
      this.isLoading = true
      const res = await this.$api.Base.User.getList(this.form).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1
        this.list.push(...res.data.data.map(i => ({...i, customerflagBool: !!i.customerflag, flagLoading: false})))

        if (this.list.length >= res.data.total) {
          this.noMore = true
        }
      } else {
        this.$message.error("获取列表失败")
      }
    },

    async openLayer(info = {}) {
      this.layerComponent = shallowRef((await import("@/views/base/user-edit/user-edit")).default)
      this.layerInfo = {...info, layerTitle: "用户修改"}
    },

    async changeFlag(flag, index) {
      // console.log(flag, index, this.list[index], this.list[index].customerflag)
      if (this.flag === !!this.list[index].customerflag) return false;
      this.list[index].flagLoading = true
      const res = await this.$api.Base.User.update({id: this.list[index].id, customerflag: Number(flag)}).catch(() => {})
      this.list[index].flagLoading = false
      if (res?.code === 200) {
        this.list[index].customerflag = Number(flag)
      } else {
        this.list[index].customerflagBool = !flag
      }
    },

    async unbind(id) {
      this.isLoading = true
      const res = await this.$api.Base.User.unbind(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("解绑成功")
        this.form.pageSize = this.list.length
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(res?.msg || "解绑失败")
      }
    },

    async del(id) {
      this.isLoading = true
      const res = await this.$api.Base.User.del(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("删除成功")
        this.form.pageSize = this.list.length - 1
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(res?.msg || "删除失败")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "user-list";
</style>
